import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaRegHospital } from 'react-icons/fa';
import { IoSchoolSharp } from 'react-icons/io5';
import { GiAlliedStar } from 'react-icons/gi';

const Experience = () => {
  return (
    <div style={{paddingBottom:'50px', backgroundColor:'#E3E3E3', paddingTop:'10px', marginTop:'-20px',}}>
    <div>
        <h1>Work Experience & Education</h1>
    </div>
    <VerticalTimeline>
      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        contentStyle={{ background: 'rgb(33, 150, 243)'}}
        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
        date='2016 - present'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>Apollo Hospital</h3>
        <h4 className='vertical-timeline-element-subtitle'>Sarita Vihar</h4>
        <p>Consultant (Gynae-Oncology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='2017 - 2023'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>Kailash Hospital</h3>
        <h2 className='vertical-timeline-element-subtitle'>
          Noida, Uttar Pradesh
        </h2>
        <p>Senior Consultant (Gynae-Oncology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='2007 - 2012'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>Sumitra Hospital</h3>
        <h2 className='vertical-timeline-element-subtitle'>
          Noida, Urrar Pradesh
        </h2>
        <p>Consultant (Obstetrics & Gynaecology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='2004 - 2007'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>Bhardwaj Hospital</h3>
        <h2 className='vertical-timeline-element-subtitle'>
          Noida, Urrar Pradesh
        </h2>
        <p>Consultant (Obstetrics & Gynaecology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='2001'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>
          Cancer Institute(W.I.A.)
        </h3>
        <h2 className='vertical-timeline-element-subtitle'>Adyar Chennai</h2>
        <p>Medical Officer (Gynae-Oncology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='1997 - 2000'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>
          Lady Hardinge Medical College
        </h3>
        <h2 className='vertical-timeline-element-subtitle'>New Delhi</h2>
        <p>Senior Resident (Obstetrics & Gynaecology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='1997'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>
          Jaipur Golden Hospital
        </h3>
        <h2 className='vertical-timeline-element-subtitle'>Delhi</h2>
        <p>Senior Resident (Obstetrics & Gynaecology)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--work'
        date='1994'
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<FaRegHospital />}
      >
        <h3 className='vertical-timeline-element-title'>D.D.U. Hospital</h3>
        <h2 className='vertical-timeline-element-subtitle'>Delhi</h2>
        <p>Resident (General Surgery)</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='2001'
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        icon={<IoSchoolSharp />}
      >
        <h3 className='vertical-timeline-element-title'>
          Fellowship and Training in gynaecology and breast cancer
        </h3>
        <h2 className='vertical-timeline-element-subtitle'>
          Cancer from Cencer Institute (W.I.A.)
        </h2>
        <p>Adyar, Chennai </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='1997'
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        icon={<IoSchoolSharp />}
      >
        <h3 className='vertical-timeline-element-title'>
          M.D. (Obstetrics and Gynaecology)
        </h3>
        <h2 className='vertical-timeline-element-subtitle'>
          Pandit B.D. Sharma PGIMS
        </h2>
        <p>Rohtak, Haryana</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='2002 - 2006'
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        icon={<IoSchoolSharp />}
      >
        <h3 className='vertical-timeline-element-title'>MBBS</h3>
        <h2 className='vertical-timeline-element-subtitle'>
          Pandit B.D. Sharma PGIMS
        </h2>
        <p>Rohtak, Haryana</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<GiAlliedStar />}
      />
    </VerticalTimeline>
    </div>
  );
};

export default Experience;



