import React, { Component } from 'react';
//import all child components
import Container from './Container';
import CompanyInfo from './CompanyInfo';
import Image from './ContactImage';

class Main extends Component {
  render() {
    return (
      <Container style={{ paddingTop: '20px', marginBottom: '20px' }}>
        <Container wrapper>
          <CompanyInfo />
          <Image />
        </Container>
      </Container>
    );
  }
}

export default Main;
