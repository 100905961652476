import image from '../../Assets/Doctor_photo.jpg';

const Image = () => {
  return (
    <div className='contact-image'>
      <img
        style={{ width: '80%', height: '450px' }}
        src={image}
        alt='Dr. Bhawna Garg'
      />
    </div>
  );
};

export default Image;
