import React from 'react';
import image from '../../Assets/Doctor_photo.jpg';
import './Home.css';
import Stack from 'react-bootstrap/Stack';

const Home = () => {
  return (
    <div style={{paddingTop:'30px' ,backgroundColor:'#E3E3E3', marginTop:'-50px'}}>
      <div class='home-container' id='home'>
        <div class='photo-container'>
          <img src={image} alt='Dr. Bhawna Garg' id='photo' />
        </div>

        <div class='home-text'>
          <h3>Dr. Bhawna Garg</h3>
          <i>
            “A dream does not become reality through magic, it takes
            determination, honesty and hard work”
          </i>
          <br />
          <br />
          <p>
            Dr. Bhawna Garg, a dedicated Gynecologic Oncologist with over 25+
            years of experience, holds MBBS and MD degrees from Pandit B.D.
            Sharma PGIMS, Rohtak, Haryana. Her expertise includes residencies at
            prominent hospitals, extensive contributions to medical literature,
            and pivotal roles at esteemed medical institutions like Cancer Institute(W.I.A.), 
            Adhyar Chennai and Lady Hardinge Medical College, New Delhi. With a commitment to excellence and a
            life philosophy emphasizing determination and hard work, Dr. Garg
            stands as a revered figure in Gynae-Oncology, embodying meticulous
            care and leadership qualities.
          </p>
        </div>
      </div>
      <div className='container'>
        <div className='list'>
          <h3>Treatments</h3>
          <div className='treatments-container'>
            <ul className='treatment-column'>
              <li>
                <span>Abnormal uterine bleeding</span>
              </li>
              <li>
                <span>Cervix cancer</span>
              </li>
              <li>
                <span>Uterus cancer</span>
              </li>
              <li>
                <span>Ovary cancer</span>
              </li>
            </ul>
            <ul className='treatment-column'>
              <li>
                <span>Ovary cyst removal</span>
              </li>
              <li>
                <span>Fibroids</span>
              </li>
              <li>
                <span>Abnormal PAPsmear and colposcopy</span>
              </li>
              <li>
                <span>Breast lump, pain, nipple discharge</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
