import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
const Footer = () => {
  return (
    <footer className='bg-light'>
      <Container fluid>
        <Row>
          <Col xs={24} md={12} lg={6}>
            <h3>Quick Links</h3>
            <Col xs={24} md={12} lg={6}>
              <ul className='list-unstyled'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
              </ul>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <ul className='list-unstyled'>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
            </Col>
          </Col>

          <Col xs={24} md={12} lg={6}>
            <Card>
              <Card.Body>
                <Card.Title>Working Hours</Card.Title>
                <Card.Text>
                  Monday - Saturday: 9am - 5pm
                  <br />
                  Sunday: Closed
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
