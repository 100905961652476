import React, { useState } from 'react';
import { Nav, Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  AiOutlineHome,
  AiOutlineFieldTime,
  AiOutlineContacts,
} from 'react-icons/ai';
import { LiaBarsSolid } from 'react-icons/lia';
import { GiStethoscope } from 'react-icons/gi';
import './Navbar.css';

const NavBar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      sticky='top'
      style={{ backgroundSize: '0', backgroundColor: '#ACE5EA' }}
    >
      <Container>
        <Navbar.Brand style={{ marginTop: '-15px' }}>
          <GiStethoscope />
          <Link to='/'>Dr. Bhawna Garg</Link>
        </Navbar.Brand>
        <Navbar.Toggle
          className='navbar-toggle-custom'
          style={{ backgroundColor: '#ACE5EA', border: 'none' }}
          onClick={toggle}
        >
          <LiaBarsSolid />
        </Navbar.Toggle>
        <Navbar.Collapse in={!collapsed}>
          <Nav className='ms-auto navbar-direction'>
            <Nav.Link
              as={Link}
              to='/'
              style={{ margin: '20px' }}
              onClick={toggle}
            >
              <AiOutlineHome /> Home
            </Nav.Link>

            <Nav.Link
              as={Link}
              to='/experience'
              style={{ margin: '20px' }}
              onClick={toggle}
            >
              <AiOutlineFieldTime /> Experience
            </Nav.Link>

            <Nav.Link
              as={Link}
              to='/contact'
              style={{ margin: '20px' }}
              onClick={toggle}
            >
              <AiOutlineContacts /> Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
