import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaUserDoctor, FaPhone } from 'react-icons/fa6';
import { IoMailOpen } from 'react-icons/io5';

const CompanyInfo = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column' /* Align items in a column */,
      justifyContent: 'center' /* Align items horizontally */,
      alignItems: 'center' /* Align items vertically */,
      backgroundColor: 'rgb(38, 38, 238)',
      padding: '10px' /* Add some padding for spacing */,
    }}
  >
    <Card
      style={{
        fontWeight: 'bold',
        fontSize: '60px',
        width: '25rem',
        backgroundColor: '#2626ee',
        borderColor: '#2626ee',
        color: 'white',
        marginBottom: '10px',
      }}
    >
      <Card.Body>
        <Card.Title
          style={{
            fontWeight: 'bold',
            fontSize: '25px',
          }}
        >
          Contact Me
        </Card.Title>
      </Card.Body>
    </Card>
    <Card
      style={{
        width: '25rem',
        backgroundColor: '#2626ee',
        borderColor: '#2626ee',
        color: 'white',
        marginBottom: '10px',
      }}
    >
      <Card.Body>
        <FaUserDoctor />
        <Card.Title>Dr. Bhawna Garg</Card.Title>
        <Card.Text>Apollo Hospitals, New Delhi</Card.Text>
      </Card.Body>
    </Card>
    <Card
      style={{
        width: '25rem',
        backgroundColor: '#2626ee',
        borderColor: '#2626ee',
        color: 'white',
        marginBottom: '10px',
      }}
    >
      <Card.Body>
        <IoMailOpen />
        <Card.Title>bhawna.garg.dr@gmail.com</Card.Title>
        <Card.Text>Need support? Drop us an email</Card.Text>
      </Card.Body>
    </Card>
    <Card
      style={{
        width: '25rem',
        backgroundColor: '#2626ee',
        borderColor: '#2626ee',
        color: 'white',
        marginBottom: '10px',
      }}
    >
      <Card.Body>
        <FaPhone />
        <Card.Title>+91-9716905309</Card.Title>
        <Card.Text>Have a question? call us now</Card.Text>
      </Card.Body>
    </Card>
    <Button
      href='https://www.apollo247.com/doctors/dr-bhawna-garg-2b73d0e8-e424-467d-8b84-44d6e44190e6?source=search_box'
      target='_blank'
      variant='success'
    >
      Book Appointment
    </Button>
  </div>
);

export default CompanyInfo;
